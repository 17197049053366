@font-face {
  font-family: "Queens Condensed Light";
  src: url("/fonts/QueensCondensed-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Queens Condensed Regular";
  src: url("/fonts/QueensCondensed-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Queens Condensed Medium";
  src: url("/fonts/QueensCondensed-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Good Sans Bold";
  src: url("/fonts/GoodSans-Bold.otf");
  font-display: swap;
}

@font-face {
  font-family: "Good Sans Light";
  src: url("/fonts/GoodSans-Light.otf");
  font-display: swap;
}

@font-face {
  font-family: "Good Sans Medium";
  src: url("/fonts/GoodSans-Medium.otf");
  font-display: swap;
}

@font-face {
  font-family: "Good Sans Regular";
  src: url("/fonts/GoodSans-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: "Basis Grotesque Mono";
  src: url("/fonts/BasisGrotesque-Mono.otf");
  font-display: swap;
}


html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  --swiper-pagination-color: #26122F;
  --swiper-pagination-bullet-inactive-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --swiper-pagination-bullet-inactive-opacity: 100%;
  height: 100%;
}

a:hover {
  opacity: 0.5;
}

#__next {
  /* min-height: 100vh; */
  height: 100%;
}
